<template>
  <div
    class="box__icon"
    :style="img != null ? { 'background-image': `url(${img})` } : {}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BoxIcon',
  props: {
    img: String,
  },
};
</script>

<style scoped></style>
