













import { Component, Vue, Prop } from 'vue-property-decorator';
import Vuescroll from 'vuescroll';
import { Pos } from 'vuescroll/types/vuescroll';

@Component({ components: { Vuescroll } })
export default class ScrollBox extends Vue {
  @Prop({ type: Boolean, default: false })
  withXScroll!: boolean;

  @Prop({ type: Boolean, default: false })
  noSpace!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  keepShow!: boolean;

  $refs!: {
    scroll: InstanceType<typeof Vuescroll>;
  };

  get scrollOpt() {
    return {
      scrollPanel: {
        scrollingX: this.withXScroll,
      },
      bar: {
        keepShow: this.keepShow,
      },
    };
  }

  scrollTo(pos: Pos, speed?: number) {
    if (this.$refs.scroll != null) {
      this.$refs.scroll.scrollTo(pos, speed);
    }
  }
}
